import React, { useState, useMemo, useEffect } from 'react';

import { isEmpty } from 'lodash';
import ReactCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Button } from 'components/common-n4/button';
import { useAudio } from 'hooks';
import { useIsMobileView } from 'hooks/use_is_mobile_view';
import { useWindowDimensions, WINDOW_DIMENSION_TYPE } from 'hooks/use_window_dimensions';
import { cx, getWindowDimensionType } from 'lib/utils';

import styles from './multi-carousel.module.scss';

const DEFAULT_ITEM_SPACE_CONFIG = {
  mobile: { width: 400, gap: 16 },
  desktop: { width: 500, gap: 32 },
  wide: { width: 500, gap: 32 },
};

interface CarouselButtonProps {
  next?: () => void;
  previous?: () => void;
  isMobile: boolean;
  isLastSlide: boolean;
  isDisable?: boolean;
  carouselState?: Record<string, string>;
}

const CarouselButtons: React.FC<CarouselButtonProps> = ({ next, previous, isMobile, isLastSlide, isDisable, carouselState }) => {
  const { currentSlide } = carouselState!;
  return (
    <div className={cx(styles['ht-multi-carousel-button-group'], isMobile && styles.mobile)}>
      <Button.Icon
        className={cx(
          styles['ht-multi-carousel-button-group-button'],
          styles[`ht-multi-carousel-button-group-button--${(!currentSlide || isDisable) && 'inactive'}`],
          styles['arrow-to-left'],
          isMobile && styles.mobile
        )}
        icon={`arrow-right-navy-blue-regular ${isMobile ? 'sm' : 'lg'}`}
        onClick={previous}
      />
      <Button.Icon
        className={cx(
          styles['ht-multi-carousel-button-group-button'],
          styles[`ht-multi-carousel-button-group-button--${(isLastSlide || isDisable) && 'inactive'}`],
          isMobile && styles.mobile
        )}
        icon={`arrow-right-navy-blue-regular ${isMobile ? 'sm' : 'lg'}`}
        onClick={next}
      />
    </div>
  );
};

interface CarouselDotProps {
  onClick?: () => void;
  isMobile: boolean;
  isDisable: boolean;
  active?: boolean;
}

const CarouselDots: React.FC<CarouselDotProps> = ({ onClick, isMobile, isDisable, ...rest }) => {
  const { active } = rest;

  if (isDisable) {
    return;
  }

  return (
    <div className={cx(styles['ht-multi-carousel-dot-list'])} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width={`${isMobile ? '100%' : '51'}`} height="6" viewBox={`0 0 ${isMobile ? '32' : '51'} 6`} fill="none">
        {active ? (
          <>
            {isMobile ? (
              <path d="M3 3H28.9431" stroke="#142546" strokeWidth="5.6603" strokeLinecap="round" />
            ) : (
              <>
                <path opacity="0.2" d="M3 3H36.2543" stroke="#142546" strokeWidth="5.6603" strokeLinecap="round" />
                <path d="M3 3H48" stroke="#142546" strokeWidth="5.6603" strokeLinecap="round" />
              </>
            )}
          </>
        ) : (
          <>
            {isMobile ? (
              <path opacity="0.2" d="M3.26367 3H29.2067" stroke="#142546" strokeWidth="5.6603" strokeLinecap="round" />
            ) : (
              <path opacity="0.2" d="M2.89551 3H47.4704" stroke="#142546" strokeWidth="5.6603" strokeLinecap="round" />
            )}
          </>
        )}
      </svg>
    </div>
  );
};

interface ItemSpaceConfig {
  desktop: {
    width: number;
    gap: number;
  };
  [key: string]: {
    width: number;
    gap: number;
  };
}

/* 
   itemSpaceConfigs is an attribute that the carousel component utilizes to configure the carousel item
   internal layout responsiveness based on device breakpoints. 
*/
interface MultiCarouselProps {
  items: React.ReactNode[];
  itemSpaceConfigs: ItemSpaceConfig;
  className?: string;
  customDot?: React.ReactElement;
  focusOnSelect?: boolean;
  renderArrowsWhenDisabled?: boolean;
  arrows?: boolean;
  customLeftArrow?: React.ReactElement;
  customRightArrow?: React.ReactElement;
}

export const MultiCarousel: React.FC<MultiCarouselProps> = ({ items, itemSpaceConfigs, className, customDot, arrows, ...restProps }) => {
  useAudio();
  const isMobile = useIsMobileView();
  const { width } = useWindowDimensions();
  const dimensionType = getWindowDimensionType(width);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [itemsPerSlide, setItemsPerSlide] = useState<number>(0);
  const [isLastSlide, setLastSlide] = useState<boolean>(false);
  const [carouselItems, setCarouselItems] = useState<React.ReactNode[]>([]);

  if (isEmpty(itemSpaceConfigs)) {
    itemSpaceConfigs = DEFAULT_ITEM_SPACE_CONFIG;
  }

  const itemSpaceConfig = useMemo(() => {
    switch (dimensionType) {
      case WINDOW_DIMENSION_TYPE.mobile:
        return itemSpaceConfigs.mobile;
      case WINDOW_DIMENSION_TYPE.wide:
        return itemSpaceConfigs.wide;
      default:
        return itemSpaceConfigs.desktop;
    }
  }, [itemSpaceConfigs, dimensionType]);

  const itemWidth = useMemo(() => {
    const defaultItemWidth = itemSpaceConfig.width + itemSpaceConfig.gap;
    return width! < defaultItemWidth ? width! - itemSpaceConfig.gap : defaultItemWidth;
  }, [itemSpaceConfig, width]);

  useEffect(() => {
    const count = Math.floor(containerWidth / itemWidth);
    setItemsPerSlide(count <= 0 ? 1 : count);
  }, [containerWidth, itemSpaceConfig, itemWidth]);

  const partialVisibilityGutter = useMemo(() => {
    return (containerWidth - itemWidth * itemsPerSlide) / itemsPerSlide;
  }, [containerWidth, itemsPerSlide, itemWidth]);

  const isButtonDisable = items.length <= itemsPerSlide;

  const responsive = {
    allDevices: {
      breakpoint: { max: Infinity, min: 0 },
      items: itemsPerSlide,
      partialVisibilityGutter: partialVisibilityGutter,
      slidesToSlide: itemsPerSlide,
    },
  };

  if (!items || isEmpty(items)) {
    return null;
  }

  return (
    <ReactCarousel
      ref={(el) => {
        if (el) {
          setContainerWidth(el.state.containerWidth);
          if (items !== carouselItems) {
            el.state.currentSlide = 0;
            setLastSlide(false);
          }
          setCarouselItems(items);
        }
      }}
      ssr
      swipeable
      showDots
      partialVisible
      focusOnSelect
      keyBoardControl
      pauseOnHover
      responsive={responsive}
      infinite={false}
      arrows={false}
      slidesToSlide={1}
      transitionDuration={200}
      className={cx(styles[className!], styles['ht-multi-carousel'])}
      dotListClass={cx(styles['ht-multi-carousel-custom-dot-list'], styles.mobile)}
      sliderClass={cx(styles['ht-multi-carousel-slider'], styles[isButtonDisable ? 'ht-multi-carousel-slider-fit-content' : ''])}
      itemClass={cx(styles['ht-multi-carousel-slider-item'])}
      customButtonGroup={<CarouselButtons isMobile={isMobile} isLastSlide={isLastSlide} isDisable={isButtonDisable} />}
      customDot={customDot || <CarouselDots isMobile={isMobile} isDisable={isButtonDisable} />}
      beforeChange={(currentSlide, { ...rest }) => {
        const { totalItems, slidesToShow } = rest;
        setLastSlide(totalItems === currentSlide + slidesToShow);
      }}
      {...restProps}
    >
      {items.map((item, index) => (
        <div className={styles.slider} key={index}>
          {item}
        </div>
      ))}
    </ReactCarousel>
  );
};
