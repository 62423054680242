import React from 'react';

import { usePathname } from 'next/navigation';

import { Button } from 'components/common-n4/button';
import { useOncePerPage } from 'hooks';
import { getAb, recordAbEvent, type AbButtonPosition } from 'lib/ab-tests';
import Logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';
import type { ReleaseLite, Model, HearingAid, Price } from 'types/release';

import { ProviderFlow } from './provider-pop-up';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = Logger({ category: 'price-button' });
/*
                    R
          TL1                TL2
  HA1(FF1) HA2(FF2)  HA3(FF1)  HA4 (FF3)

  For the purpose of showing the popup:
    if the card is showing a hearing aid:
      HA1 is a go if we have HA1 or TL1
      HA2 is a go if we have HA2 or TL1
      HA3 is a go if we have HA3 or TL2
      HA4 is a go if we have HA4 or TL2

    if the card is showing a form factor (model):
      FF3 is a go if we have TL1 or TL2
      FF2 is only if we have TL1
      FF3 is only if we have TL2

  For the purpose of showing the starting price:
    All of the above applies, the only difference
    is the ha price is used if there is one.

*/

interface PriceButtonProps {
  release: ReleaseLite;
  model?: Model;
  hearingAid?: HearingAid;
  price?: Price;
  className?: string;
  onModalOpenChange?: (isOpening: boolean) => void;
  noGeo: boolean;
  origin: string;
  position: AbButtonPosition;
}

const elementType = 'button';
const event = 'AffiliateAction';

const utmize = (url: string, content: string, term: string) => {
  const newUrl = new URL(url);
  newUrl.searchParams.set('utm_campaign', 'lead-gen');
  newUrl.searchParams.set('utm_medium', 'button');
  newUrl.searchParams.set('utm_content', content);
  newUrl.searchParams.set('utm_term', term);
  return newUrl.toString();
};

const generateTestId = ({ release, model, hearingAid }: { release?: ReleaseLite; model?: Model; hearingAid?: HearingAid }): string => {
  if (hearingAid) {
    return `ha-${hearingAid.slug || hearingAid.id}`;
  }
  if (model) {
    return `mo-${model.slug || model.id}`;
  }
  if (release) {
    return `r-${release.slug}`;
  }
  return '';
};

const PriceButton: React.FC<PriceButtonProps> = ({ release, model, hearingAid, price, className, onModalOpenChange, origin, position }) => {
  const [isProviderShowing, showProvider] = React.useState<boolean>(false);
  const [segment, setSegment] = React.useState(-2);
  const [popupSegment, setPopupSegment] = React.useState(-2);
  const useOnce = useOncePerPage();
  const pathname = usePathname();
  const [buttonState, setButtonState] = React.useState({ text: 'Loading...', link: '', disabled: true, shouldOpenTab: false, cta: '', seller: '' });
  const [clicked, setClicked] = React.useState(0);
  const random = React.useRef(String(Math.floor(Math.random() * 10 ** 8)));

  const testName = position;
  const partnerType = price?.partner_priority ? 'lead-gen' : 'affiliate';
  const testId = generateTestId({ release, model, hearingAid });

  if (useOnce({ item: `${testName}-${testId}-impression`, skip: !release || segment < 0 })) {
    recordAbEvent({
      event,
      testName,
      segment,
      origin,
      properties: {
        action: 'impression',
        elementType,
        partnerType,
        release: release.slug,
        seller: price?.seller || '',
        position,
        link: price?.url ? utmize(price.url, testName, random.current) : '',
        random: random.current,
        testId,
      },
    });
  }

  if (useOnce({ item: `${testName}-${testId}-click`, skip: !release || segment < 0 || clicked !== 1 })) {
    recordAbEvent({
      event,
      testName,
      segment,
      origin,
      properties: {
        action: 'click',
        elementType,
        partnerType,
        release: release.slug,
        seller: price?.seller || '',
        position,
        link: price?.url ? utmize(price.url, testName, random.current) : '',
        random: random.current,
        testId,
      },
    });
  }

  React.useEffect(() => {
    const onReleasePage = pathname === release.path;
    if (!price) {
      if (onReleasePage) {
        setButtonState({ text: 'No Offers', link: '', disabled: true, shouldOpenTab: false, cta: '', seller: '' });
      } else {
        if (!release.path) {
          setButtonState({
            text: 'Archived Product',
            link: '',
            disabled: true,
            shouldOpenTab: false,
            cta: '',
            seller: '',
          });
        } else {
          setButtonState({
            text: 'Product Info',
            link: normalizeUrl({ url: release.path, origin: 'price-button' }),
            disabled: false,
            shouldOpenTab: false,
            cta: 'Product Info',
            seller: '',
          });
        }
      }
    } else if (price.url) {
      const { segment: pseg } = getAb('providerFlow');
      setPopupSegment(pseg);
      const { cta, text, segment: seg } = getAb(testName, { release: release.name });
      setSegment(seg);
      setButtonState({ text, link: utmize(price.url, testName, random.current), disabled: false, shouldOpenTab: true, cta, seller: price.seller });
    } else {
      if (onReleasePage) {
        // Maybe this should be a anchor to model comparison section?
        setButtonState({ text: 'No Current Offers', link: '', disabled: true, shouldOpenTab: false, cta: '', seller: price.seller });
      } else {
        if (!release.path) {
          setButtonState({
            text: 'Archived Product',
            link: '',
            disabled: true,
            shouldOpenTab: false,
            cta: '',
            seller: '',
          });
        } else {
          setButtonState({
            text: 'View Available Prices',
            link: normalizeUrl({ url: release.path, origin: 'price-button' }),
            disabled: false,
            shouldOpenTab: false,
            cta: 'View Available Prices',
            seller: price.seller,
          });
        }
      }
    }
  }, [price, pathname, release.path, release.name, testName, random]);

  const handleSearch = () => {
    setClicked((count) => count + 1);
    showProvider(true);
    if (onModalOpenChange) {
      onModalOpenChange(true);
    }
  };

  const onClose = () => {
    showProvider(false);
    if (onModalOpenChange) {
      onModalOpenChange(false);
    }
  };

  if (popupSegment < 0) {
    return (
      <Button.LinkPrimary
        className={className || '!w-full'}
        onClick={() => {
          setClicked((count) => count + 1);
          return true;
        }}
        href={buttonState.link}
        shouldOpenNewTab={buttonState.shouldOpenTab}
        loader="false"
        data-track={!!buttonState.cta}
        data-event-name="Button"
        data-event-position={position}
        data-event-product={release.slug}
        data-event-cta={buttonState.cta}
        data-event-seller={buttonState.seller}
        data-event-url={buttonState.link}
      >
        Learn More
      </Button.LinkPrimary>
    );
  }
  if (popupSegment > 10 && buttonState.cta && price?.provider_search) {
    // display popup
    return (
      <>
        <ProviderFlow
          release={release}
          model={model}
          hearingAid={hearingAid}
          onClose={onClose}
          isModalOpen={isProviderShowing}
          origin={origin}
          segment={segment}
          position={position}
          segmentOrigin="button"
        />
        <Button.Primary className={className || '!w-full'} onClick={handleSearch} isFull>
          {buttonState.text}
        </Button.Primary>
      </>
    );
  }

  if (buttonState.link) {
    return (
      <Button.LinkPrimary
        className={className || '!w-full'}
        onClick={() => {
          setClicked((count) => count + 1);
          return true;
        }}
        href={buttonState.link}
        shouldOpenNewTab={buttonState.shouldOpenTab}
        loader="false"
        data-track={!!buttonState.cta}
        data-event-name="Button"
        data-event-position={position}
        data-event-product={release.slug}
        data-event-cta={buttonState.cta}
        data-event-seller={buttonState.seller}
        data-event-url={buttonState.link}
      >
        {buttonState.text}
      </Button.LinkPrimary>
    );
  }

  return (
    <Button.Primary
      className={className || '!w-full'}
      isFull
      isDisabled={buttonState.disabled}
      onClick={() => {
        setClicked((count) => count + 1);
        return true;
      }}
    >
      {buttonState.text}
    </Button.Primary>
  );
};

export default PriceButton;
