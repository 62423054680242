import React, { useCallback, useEffect, useRef, useState } from 'react';

import { tw } from 'lib/utils';

import { Panel } from './panel';

interface TOCItemProps {
  sectionId: string;
  title: string;
  currentTarget: CurrentTarget;
  setCurrentTarget: React.Dispatch<React.SetStateAction<CurrentTarget | undefined>>;
}

const TOCItem: React.FC<TOCItemProps> = ({ sectionId, title, currentTarget, setCurrentTarget }) => {
  const itemRef = useRef<HTMLLIElement>(null);
  const [isInView, setIsInView] = useState(false);
  const prevScrollY = useRef<number>();

  React.useEffect(() => {
    prevScrollY.current = window.scrollY;
  }, []);

  const onClick = useCallback(() => {
    const target = document.getElementById(sectionId) as HTMLElement;
    const currentScrollY = window.scrollY;
    const targetOffsetTop = target?.offsetTop || 0;
    const scrollDirection = targetOffsetTop > currentScrollY ? 'down' : 'up';
    const offset = scrollDirection === 'down' ? -20 : -70;
    window.scrollTo({ top: targetOffsetTop + offset, behavior: 'smooth' });
  }, [sectionId]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollDirection = currentScrollY > (prevScrollY.current || 0) ? 'down' : 'up';
      prevScrollY.current = currentScrollY;

      const target = document.getElementById(sectionId) as HTMLElement;
      const targetTop = target.getBoundingClientRect().top;
      const isNearTop = targetTop <= window.innerHeight * 0.2; // Adjust this value as needed
      const isAboveHalfway = targetTop < window.innerHeight / 2;

      if (scrollDirection === 'up' && isAboveHalfway) {
        // Highlight the previous section when scrolling up
        setCurrentTarget((prevTarget) => {
          const prevSectionId = itemRef.current?.previousElementSibling?.id || sectionId;
          return prevSectionId !== prevTarget?.target ? { target: prevSectionId } : prevTarget;
        });
      } else if (scrollDirection === 'down' && isNearTop) {
        // Highlight the current section when it's near the top while scrolling down
        setCurrentTarget({ target: sectionId });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionId, setCurrentTarget]);

  useEffect(() => {
    setIsInView(currentTarget.target === sectionId);
  }, [currentTarget, sectionId]);

  useEffect(() => {
    if (isInView) {
      itemRef.current?.classList.add('border-lapis', 'text-navy');
      itemRef.current?.classList.remove('border-transparent', 'text-navy/40');
    } else {
      itemRef.current?.classList.add('border-transparent', 'text-navy/40');
      itemRef.current?.classList.remove('border-lapis', 'text-navy');
    }
  }, [isInView]);

  return (
    <li ref={itemRef} className={tw('border-l-2 pl-4 leading-5 text-navy/40')}>
      <button className="text-left text-[18px] leading-6 tracking-tight" onClick={onClick}>
        {title}
      </button>
    </li>
  );
};

interface TableOfContentsItem {
  sectionId: string;
  title: string;
}

interface TableOfContentsProps {
  toc: TableOfContentsItem[];
}

interface CurrentTarget {
  target?: string;
}

export const TableOfContents: React.FC<TableOfContentsProps> = ({ toc }) => {
  const [currentTarget, setCurrentTarget] = useState<CurrentTarget | undefined>({ target: undefined });

  return (
    <Panel className="flex flex-col gap-6">
      <div className="text-xl leading-7 tracking-tight text-navy">Table of Contents</div>
      <ul className="flex flex-col gap-4">
        {toc.map(({ sectionId, title }: TableOfContentsItem) => (
          <TOCItem key={sectionId} sectionId={sectionId} title={title} currentTarget={currentTarget!} setCurrentTarget={setCurrentTarget} />
        ))}
      </ul>
    </Panel>
  );
};
