/* eslint no-console: 'off' */
// import util from 'util';
import { createFormat } from '@edge-runtime/format';
import dayjs from 'dayjs';

const format = createFormat();
const logLevel = typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('logLevel') || 'warn' : process?.env?.LOG_LEVEL || 'warn';
const logCategory = typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('logCategory') : process?.env?.LOG_CATEGORY;

// Note that logLevel and LogCategory need to be set in .env to affect server-side rendering.

const levels = ['error', 'warn', 'info', 'debug', 'silly'];

// Workaround due to the fact that vercel combines all logs from a request
// into a single entry but then truncates from the beginning so you lose
// the most important part. This logs directly to our server. Logs may be out
// of order and probably wouldn't do this in prod, but good for debugging.
const logRemotely = async (args) => {
  if (process.env.NODE_ENV === 'development') {
    return null;
  }
  const remote = /x-remote/.test(args);
  if (!remote && typeof window !== 'undefined') {
    return null;
  }
  const clause = remote ? '?remote=true' : '';
  const ref = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || process.env.VERCEL_GIT_COMMIT_REF;
  const key = process.env.NEXT_PUBLIC_REMOTE_LOG_KEY || process.env.REMOTE_LOG_KEY;
  console.debug(ref);
  try {
    const url = ref === 'main' ? `https://logs.hearingtracker.com${clause}` : `https://logs-staging.hearingtracker.com${clause}`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-VERCEL-KEY': key,
      },
      body: JSON.stringify({ timestamp: dayjs().toISOString(), message: args }),
    };
    return fetch(url, options);
  } catch (ex) {
    console.error('error sending logs: %o', ex);
  }
  return null;
};

const genId = () => [...Array(20)].map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 65)).join('');

const logger = ({ category, request = null }) => {
  const requestId = request?.headers?.['x-vercel-id'] || genId();
  const metadata = [requestId];
  if (request?.path) {
    metadata.push(request.path);
  }
  if (request?.ip) {
    metadata.push(request.ip);
  }
  const meta = `[${metadata.join('|')}]`;
  const l = {
    category,
    level: logLevel || undefined,
    requestId,

    isLogging: (checkLevel) => levels.indexOf(checkLevel) <= levels.indexOf(logLevel),

    solo: (...args) => {
      // Use solo when you are in dev and want to just log a specific line without
      // logging everything else. Or I guess use console.log instead.
      if (logLevel === 'solo') {
        const [message, ...rest] = args;
        console.log(`[solo] ${category}: ${message}${meta}`, ...rest);
      }
      return true;
    },

    silly: (...args) => {
      if (logLevel === 'silly' && (logCategory || category) === category) {
        const [message, ...rest] = args;
        console.debug(`[silly] ${category}: ${message}${meta}`, ...rest);
      }
      return true;
    },

    debug: (...args) => {
      if (/silly|debug/.test(logLevel) && (logCategory || category) === category) {
        const [message, ...rest] = args;
        console.debug(`[debug] ${category}: ${message}${meta}`, ...rest);
        // logRemotely(format(`[x-debug] ${category}: ${message}${meta}`, ...rest));
      }
      return true;
    },

    info: (...args) => {
      if (/silly|debug|info/.test(logLevel) && (logCategory || category) === category) {
        const [message, ...rest] = args;
        console.log(`[info] ${category}: ${message}${meta}`, ...rest);
        // return logRemotely(format(`[x-info] ${category}: ${message}${meta}`, ...rest));
      }
      return true;
    },

    // Force log remotely for tracking client stuff
    remote: (...args) => {
      const [message, ...rest] = args;
      console.log(`[info] ${category}: ${message}${meta}`, ...rest);
      if (process.env.NODE_ENV === 'development') {
        return null;
      }
      return logRemotely(format(`[x-remote] ${category}: ${message}${meta}`, ...rest));
    },

    warn: (...args) => {
      if (logLevel !== 'error' && (logCategory || category) === category) {
        const [message, ...rest] = args;
        console.warn(`[warn] ${category}: ${message}`, ...rest);
        return logRemotely(format(`[x-warn] ${category}: ${message}${meta}`, ...rest));
      }
      return null;
    },

    error: (...args) => {
      if ((logCategory || category) === category) {
        const [message, ...rest] = args;
        console.error(`[error] ${category}: ${message}`, ...rest);
        return logRemotely(format(`[x-error] ${category}: ${message}${meta}`, ...rest));
      }
      return null;
    },
  };
  return l;
};

export default logger;
