import { ClassNameValue } from 'tailwind-merge';

import { tw } from 'lib/utils';

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
  className?: ClassNameValue;
  activeClassName?: ClassNameValue;
}

export const CustomDot: React.FC<CustomDotProps> = ({ active, onClick, className, activeClassName }) => {
  return (
    <li className="flex-1">
      <button className={tw('h-[5px] w-full rounded-full bg-navy/20', className, active && (activeClassName || 'bg-navy'))} onClick={onClick}></button>
    </li>
  );
};
