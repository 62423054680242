import React, { useContext, useMemo } from 'react';

import { motion } from 'framer-motion';
import { getTrackBackground, Range as ReactRange } from 'react-range';

import logger from 'lib/logger';
import { tw } from 'lib/utils';

import { PriceRange, UserAction } from './types';
import { findMatches, findMinMaxDiff } from './utils';

import { WizardContext } from '.';

// dummy data

const log = logger({ category: 'wizard/range' });

const STEP = 1;
const NUMBER_OF_RANGES = 22;

interface InputProps {
  priceRange: PriceRange;
  userPriceRange: PriceRange;
  setUserPriceRange: (priceRange: PriceRange) => void;
}

interface BarsProps {
  priceRange: PriceRange;
  userPriceRange: PriceRange;
  largestQuantity: number;
  data: number[];
}

const maxHeight = 100;

// Logarithmic transformation function
const transformCounts = (counts: number[]) => {
  // Apply log transformation
  const transformedCounts = counts.map((count) => Math.log(count));
  // Normalize transformed counts to the max height
  const maxTransformed = Math.max(...transformedCounts);
  return transformedCounts.map((height) => (height / maxTransformed) * maxHeight);
};

const Bars: React.FC<BarsProps> = ({ priceRange, userPriceRange, largestQuantity, data }) => {
  const diff = priceRange.max - priceRange.min;
  // Transform the bar heights
  const scaledHeights = transformCounts(data);
  return (
    <div className="bars flex h-[60px] gap-1 overflow-hidden px-[2px] lg:h-[35px]">
      {scaledHeights.map((height, index) => {
        const datum = data[index];
        const sectionWidth = diff / data.length;
        const barLeft = sectionWidth * index;
        const barMiddle = barLeft + sectionWidth / 2 + 80;
        // const barRight = barLeft + sectionWidth;
        // 0 is the full height; 100 is flat
        const pct = 100 - height;
        // Non-scaled value is:
        // const pct = (1 - datum / (largestQuantity || 1)) * 100;
        // but that makes the bigger bars drown out the smaller ones
        // so using an scaled value to even them out a bit.
        return (
          <motion.div
            key={index}
            data-count={datum}
            data-adjusted={height}
            data-percent={(1 - datum / (largestQuantity || 1)) * 100}
            data-lg={largestQuantity}
            animate={{ translateY: `${pct}%` }}
            transition={{ ease: 'easeOut' }}
            className={tw(
              'bar h-[60px] flex-1 basis-[14px] rounded-t-sm bg-lapis lg:h-[35px]',
              (barMiddle < userPriceRange.min || barMiddle > userPriceRange.max) && 'bg-white-40'
            )}
            title={`${datum} hearing aid${datum == 1 ? '' : 's'}`}
          ></motion.div>
        );
      })}
    </div>
  );
};

const Input: React.FC<InputProps> = ({ priceRange, userPriceRange, setUserPriceRange }) => {
  const { dispatch } = useContext(WizardContext);

  const finalizePriceRange = (min: number, max: number) => {
    dispatch({ type: UserAction.PriceRange, payload: { userPriceRange: { min, max } } });
  };

  return (
    <div className="mt-[32px] flex items-center justify-between gap-12 lg:mt-4 lg:gap-0">
      <div
        className={tw(
          'flex w-[116px] flex-1 items-center justify-between gap-1 rounded-[10px] px-[14px] py-2',
          'shadow-[0_0_0_1px_#E7E9EC] has-[:focus]:shadow-[0_0_0_3px_#4A7FE5] lg:flex-auto'
        )}
      >
        <span className="text-[12px] text-navy/60">Min</span>
        <div className="flex flex-1 items-center justify-end">
          <span className="">$</span>
          <input
            type="number"
            className="hide-number-spin-button w-[40px] text-right text-[18px] focus:outline-0 lg:text-[16px]"
            value={userPriceRange.min}
            onChange={(evt) => {
              const value = Number(evt.target.value);
              setUserPriceRange({ min: value, max: userPriceRange.max });
            }}
            onBlur={(evt) => {
              const value = Number(evt.target.value);
              finalizePriceRange(Math.max(value, priceRange.min), userPriceRange.max);
            }}
          />
        </div>
      </div>
      <div className="h-[1px] w-[48px] bg-navy"></div>
      <div
        className={tw(
          'flex w-[116px] flex-1 items-center justify-between gap-1 rounded-[10px] ',
          'px-[14px] py-2 shadow-[0_0_0_1px_#E7E9EC] has-[:focus]:shadow-[0_0_0_3px_#4A7FE5] lg:flex-auto'
        )}
      >
        <span className="text-[12px] text-navy/60">Max</span>
        <div className="flex flex-1 items-center justify-end">
          <span className="">$</span>
          <input
            type="number"
            className="hide-number-spin-button w-[40px] text-right text-[18px] focus:outline-0 lg:text-[16px]"
            value={userPriceRange.max}
            onChange={(evt) => {
              const value = Number(evt.target.value);
              setUserPriceRange({ min: userPriceRange.min, max: value });
            }}
            onBlur={(evt) => {
              const value = Number(evt.target.value);
              finalizePriceRange(userPriceRange.min, Math.min(value, priceRange.max));
            }}
          />
        </div>
      </div>
    </div>
  );
};

const toPriceArray = (priceRange: PriceRange) => [priceRange.min, priceRange.max];
const toPriceObject = (min: number, max: number) => ({ min, max });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Range: React.FC = () => {
  const { state, dispatch } = useContext(WizardContext);
  const { products, answers, userPriceRange } = state;
  const [tempPriceRange, setTempPriceRange] = React.useState(userPriceRange);

  React.useEffect(() => {
    setTempPriceRange(userPriceRange);
  }, [userPriceRange]);

  // This one has nothing to do with user price range,
  // it just counts prices based on the user's answers.
  // number of ranges = 3
  // min 0
  // max 300
  // 0 - 100, 100 - 200, 200 - 300
  const [data, largestQuantity, priceRange] = useMemo(() => {
    const result: number[] = Array(NUMBER_OF_RANGES).fill(0);
    const matches = findMatches({ products, answers, log });
    const pr = findMinMaxDiff(products);
    const diff = pr.max - pr.min;
    const priceRangeOfEachBar = Math.ceil(diff / NUMBER_OF_RANGES);
    matches.forEach((product) => {
      const price = product.price?.purchase_price;
      if (price) {
        // 0: 0-100
        // 1: 100-200
        // 2: 200-300
        // if price is 75
        //   75 / 100 = 0
        // if price is 150
        //   150 / 100 = 1
        const bucket = Math.floor((Number(price) - pr.min) / priceRangeOfEachBar);
        result[bucket] += 1;
      }
    });
    return [result, Math.max(...result), pr];
  }, [products, answers]);

  // console.log('values: %o, step: %o, min: %o, max: %o', toPriceArray(tempPriceRange), STEP, priceRange.min, priceRange.max);
  // console.log('data: %o', data);
  return (
    <>
      <div>
        <Bars priceRange={priceRange} largestQuantity={largestQuantity} userPriceRange={tempPriceRange} data={data} />
        <ReactRange
          label="Select your value"
          step={STEP}
          min={0}
          max={priceRange.max}
          values={toPriceArray(tempPriceRange)}
          onChange={(newValues) => {
            setTempPriceRange(toPriceObject(newValues[0], newValues[1]));
          }}
          onFinalChange={(newValues) => {
            dispatch({ type: UserAction.PriceRange, payload: { userPriceRange: toPriceObject(newValues[0], newValues[1]) } });
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '2px',
                width: '100%',
                background: getTrackBackground({
                  values: toPriceArray(tempPriceRange),
                  colors: ['#ccc', '#4a7fe5', '#ccc'],
                  min: priceRange.min,
                  max: priceRange.max,
                }),
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              key={props.key}
              style={{
                ...props.style,
                height: '24px',
                width: '24px',
                borderRadius: '50%',
                border: '1px solid rgba(20, 37, 70, 0.80)',
                backgroundColor: '#fff',
              }}
            />
          )}
        />
      </div>
      <Input priceRange={priceRange} userPriceRange={tempPriceRange} setUserPriceRange={setTempPriceRange} />
    </>
  );
};
