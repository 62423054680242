/* eslint-disable max-len */
import React, { useCallback, useMemo } from 'react';

import { Tab, Transition } from '@headlessui/react';
import * as Popover from '@radix-ui/react-popover';
import * as Progress from '@radix-ui/react-progress';
import { ClassNameValue } from 'tailwind-merge';

import { MultiCarousel } from 'components/bloks/n4/home/multi-carousel/multi-carousel';
import CircularAudioPlayer from 'components/common-n4/audio/circular-audio-player';
import { AUDIO_SCENES } from 'components/common-n4/audio/constants';
import ImageWrapper from 'components/common-n4/image';
import fonts from 'components/layout/fonts';
import { useAudio } from 'hooks';
import { useWindowDimensions, WINDOW_DIMENSION_TYPE } from 'hooks/use_window_dimensions';
import IconExpertChoiceImage from 'images/expert-choice.png';
import IconArrowAbove from 'images/icon-arrow-above.svg';
import IconArrowBelow from 'images/icon-arrow-below.svg';
import IconBar from 'images/icon-bar.svg';
import IconInfo from 'images/icon-info.svg';
import logger from 'lib/logger';
import { cx, tw, getWindowDimensionType } from 'lib/utils';
import type { BlokImage } from 'types/blok';
import type { Release, Audio as AudioType, Score } from 'types/release';

import { CustomDot } from '../home/multi-carousel/custom-dots';
import { Tooltip, TooltipSubtitle } from '../tooltip';

const log = logger({ category: 'n4/SoundPerformanceLabResults' });

interface SoundPerformanceLabResultsProps {
  release: Release;
  labResultImages: BlokImage[];
}

interface SoundPerformanceTabProps extends React.PropsWithChildren {
  selected: boolean;
}

const SoundPerformanceTab: React.FC<SoundPerformanceTabProps> = ({ selected, children }) => {
  return (
    <div className={tw('flex items-center gap-4 rounded-full border border-black/10 px-[20px] py-[12px] lg:px-5 lg:py-3', selected && 'bg-lapis')}>
      <span className={tw('text-[16px] leading-[130%] -tracking-[0.48px] text-navy lg:text-base', selected && 'text-white')}>{children}</span>
    </div>
  );
};

interface ComparisonTagProps {
  diff: number;
}

const ComparisonTag: React.FC<ComparisonTagProps> = ({ diff }) => {
  let text, icon;
  if (diff > 0) {
    text = `${Math.abs(diff).toFixed(1)} higher than average`;
    icon = <IconArrowAbove />;
  } else if (diff < 0) {
    text = `${Math.abs(diff).toFixed(1)} lower than average`;
    icon = <IconArrowBelow />;
  } else {
    text = `${Math.abs(diff).toFixed(1)} same as average`;
    icon = <IconBar />;
  }
  return (
    <span className="inline-flex items-center gap-[6px] rounded-full lg:gap-[4px]">
      {icon}
      <span className="text-[14px] lg:text-sm lg:font-normal">{text}</span>
    </span>
  );
};

interface RecordingProps {
  title: string;
  audio?: AudioType;
  className?: ClassNameValue;
}

const Recording: React.FC<RecordingProps> = ({ title, audio, className }) => (
  <li className={tw('rounded-[10px] border border-navy-10', className)}>
    <div className="flex min-h-[112px] w-full items-center justify-between px-[24px] py-[25px] text-navy lg:px-5 lg:py-6">
      <CircularAudioPlayer audio={audio} title={title} />
    </div>
  </li>
);

interface SoundPerformanceTabPanelProps {
  environment: Score;
  audios: AudioType[];
  sceneId: string;
  title: string;
  expertChoiceWinner: boolean;
  tooltipMessage: string;
  slug: string;
}

const SoundPerformanceTabPanel: React.FC<SoundPerformanceTabPanelProps> = ({
  environment,
  audios,
  sceneId,
  title,
  expertChoiceWinner,
  tooltipMessage,
  slug,
}) => {
  const [overallScore, setOverallScore] = React.useState(0);
  useAudio({ sceneId });

  React.useEffect(() => {
    if (!environment?.score) {
      log.error('No score for %o', { slug, environment, sceneId, title });
      return () => null;
    }
    const timer = setTimeout(() => setOverallScore(environment.score * 20), 0);
    return () => clearTimeout(timer);
  }, [environment, sceneId, title, slug]);

  return (
    <>
      <section className="flex flex-col gap-[48px] lg:flex-row lg:gap-28">
        <div className="flex-[11_11_0%]">
          <div className="mb-9 flex items-center justify-between lg:mb-8">
            <h3 className="text-[20px] font-medium -tracking-[0.6px] text-navy lg:text-xl">{title} Score</h3>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button type="button">
                  <IconInfo />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content sideOffset={5} side="top">
                  <Tooltip>
                    <TooltipSubtitle>{tooltipMessage}</TooltipSubtitle>
                  </Tooltip>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>

          <div className="flex flex-col gap-[16px] rounded-[20px] p-7 shadow-[0_4px_20px_0_rgba(0,0,0,0.10)] lg:gap-4">
            <div className="flex items-start justify-between">
              <span className="text-[64px] font-medium leading-[120%] -tracking-[2.4px] text-[#1B003D] lg:text-[80px] lg:leading-[90%]">
                {environment.score?.toFixed(1)}
              </span>
              {expertChoiceWinner && (
                <>
                  <ImageWrapper image={IconExpertChoiceImage} origin="test-page" sizingProps={{ nativeWidth: 76 }} imgProps={{ className: 'mt-2 lg:mt-0' }} />
                </>
              )}
            </div>
            <ComparisonTag diff={environment.comparison} />
            <Progress.Root className="relative h-[8px] w-full overflow-hidden rounded-full bg-neutral-300" value={overallScore}>
              <Progress.Indicator
                className="h-full w-full bg-purple transition-transform duration-500"
                style={{ transform: `translateX(-${100 - overallScore}%)` }}
              />
            </Progress.Root>
          </div>

          <div className="mt-[40px] leading-[140%]">
            <h5 className="text-base -tracking-[0.42px] text-navy/70 lg:text-sm">Summary</h5>
            <p className="mt-[8px] text-base leading-[1.7] text-navy md:font-light lg:mt-2 lg:text-xl lg:leading-[140%] lg:-tracking-[0.6px]">
              {environment.summary}
            </p>
          </div>
        </div>
        <div className="flex-[12_12_0%]">
          <Popover.Root>
            <div className="mb-9 flex items-center justify-between lg:mb-8">
              <h3 className="text-[20px] font-medium -tracking-[0.6px] text-navy lg:text-xl">Audio Recordings</h3>
              <Popover.Trigger asChild>
                <button type="button">
                  <IconInfo />
                </button>
              </Popover.Trigger>
            </div>
            <Popover.Portal>
              <Popover.Content sideOffset={5} side="top">
                <Tooltip>
                  <TooltipSubtitle>
                    Adjust volume to make &quot;without hearing aids&quot; as loud as it would be in the real world, without hearing aids. Remove hearing aids
                    while auditioning. High-quality headphones will improve results!
                  </TooltipSubtitle>
                </Tooltip>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>

          <ul className="flex flex-col gap-[16px] lg:gap-4">
            <Recording audio={audios[0]} title="Without Devices" />
            <Recording audio={audios.find((audio) => audio.tuned === false && audio.environment === sceneId)} title="Initial Device Settings" />
            <Recording audio={audios.find((audio) => audio.tuned && audio.environment === sceneId)} title="Tuned Device Settings" />
          </ul>
        </div>
      </section>
    </>
  );
};

const carouselConfig = {
  mobile: { width: 396, gap: 12 },
  desktop: { width: 396, gap: 12 },
};

export const SoundPerformanceLabResults: React.FC<SoundPerformanceLabResultsProps> = ({ release, labResultImages }) => {
  log.debug('release: %o', release);
  const hearingAid = release.hearing_aids[0];
  const { scores, audios, expertChoiceWinner } = hearingAid;
  const [sceneId, setSceneId] = React.useState('noisy');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isShowing, setIsShowing] = React.useState(true);

  const { width } = useWindowDimensions();
  const dimensionType = getWindowDimensionType(width);

  const labResultImagesList = useMemo(
    () =>
      labResultImages?.map((image) => (
        <figure className="h-[320px] max-w-[396px]" key={image?.id}>
          <ImageWrapper image={image} origin="test-page" imgProps={{ className: 'rounded-3xl h-full object-cover' }} />
          {image.title && <figcaption className="mt-[16px] border-l-2 border-lapis px-[8px] text-base font-light leading-[140%]">{image.title}</figcaption>}
        </figure>
      )),
    [labResultImages]
  );

  const audioData = React.useMemo(
    () => ({
      noisy: [{ ...AUDIO_SCENES.find((a) => a.title === 'Cafe') }, ...(audios?.filter((audio) => audio.environment === 'noisy') || [])],
      quiet: [{ ...AUDIO_SCENES.find((a) => a.title === 'Office') }, ...(audios?.filter((audio) => audio.environment === 'quiet') || [])],
      music: [{ ...AUDIO_SCENES.find((a) => a.title === 'Music') }, ...(audios?.filter((audio) => audio.environment === 'music') || [])],
    }),
    [audios]
  );

  const onTabClick = useCallback((index: number) => {
    setIsShowing(false);
    setTimeout(() => {
      setIsShowing(true);
      setSelectedIndex(index);
    }, 100);
  }, []);

  return (
    <Tab.Group
      as="section"
      selectedIndex={selectedIndex}
      onChange={onTabClick}
      className={cx(
        fonts.hankenGrotesk.className,
        'flex max-w-[1344px] flex-col gap-14 overflow-hidden rounded-[20px] bg-white px-[24px] py-[32px] shadow-xl lg:gap-12 lg:p-12 lg:pb-16'
      )}
    >
      <div className="flex flex-col gap-[24px] border-b border-neutral-300 pb-7 lg:block lg:pb-8">
        <h2 className="text-xl -tracking-[0.54px] lg:hidden">Filter scores by:</h2>
        <Tab.List as="ul" className="flex flex-row flex-wrap gap-2 lg:items-center lg:gap-4">
          {audioData.noisy.length > 1 && (
            <li className="whitespace-nowrap lg:whitespace-normal">
              <Tab className="w-full font-light outline-0 lg:w-auto" onClick={() => setSceneId('noisy')}>
                {({ selected }) => <SoundPerformanceTab selected={selected}>Speech in Noise</SoundPerformanceTab>}
              </Tab>
            </li>
          )}
          {audioData.quiet.length > 1 && (
            <li className="whitespace-nowrap lg:whitespace-normal">
              <Tab className="w-full font-light outline-0 lg:w-auto" onClick={() => setSceneId('quiet')}>
                {({ selected }) => <SoundPerformanceTab selected={selected}>Speech in Quiet</SoundPerformanceTab>}
              </Tab>
            </li>
          )}
          {audioData.music.length > 1 && (
            <li className="whitespace-nowrap lg:whitespace-normal">
              <Tab className="w-full font-light outline-0 lg:w-auto" onClick={() => setSceneId('music')}>
                {({ selected }) => <SoundPerformanceTab selected={selected}>Streaming Music</SoundPerformanceTab>}
              </Tab>
            </li>
          )}
        </Tab.List>
      </div>
      <Tab.Panels as="section">
        <Transition
          show={isShowing}
          enter="transform transition duration-[400ms]"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100"
          leaveTo="opacity-0 scale-95 "
        >
          {audioData.noisy.length > 1 && (
            <Tab.Panel>
              <SoundPerformanceTabPanel
                title="Speech in Noise"
                environment={scores?.noisy}
                audios={audioData.noisy}
                sceneId={sceneId}
                expertChoiceWinner={expertChoiceWinner}
                tooltipMessage="Speech intelligibility improvement in loud environments"
                slug={release.slug}
              />
            </Tab.Panel>
          )}
          {audioData.quiet.length > 1 && (
            <Tab.Panel>
              <SoundPerformanceTabPanel
                title="Speech in Quiet"
                environment={scores?.quiet}
                audios={audioData.quiet}
                sceneId={sceneId}
                expertChoiceWinner={expertChoiceWinner}
                tooltipMessage="Speech intelligibility improvement in quiet and moderate environments"
                slug={release.slug}
              />
            </Tab.Panel>
          )}
          {audioData.music.length > 1 && (
            <Tab.Panel>
              <SoundPerformanceTabPanel
                title="Streaming Music"
                environment={scores?.music}
                audios={audioData.music}
                sceneId={sceneId}
                expertChoiceWinner={expertChoiceWinner}
                tooltipMessage="Streaming music provides a full range of bass and treble"
                slug={release.slug}
              />
            </Tab.Panel>
          )}
        </Transition>
      </Tab.Panels>
      {labResultImages.length > 0 && (
        <section className="mt-4">
          <h3 className="mb-7 text-[20px] font-medium -tracking-[0.6px] text-navy lg:mb-6 lg:font-light lg:leading-[200%]">Test Imagery</h3>
          {dimensionType === WINDOW_DIMENSION_TYPE.md || dimensionType === WINDOW_DIMENSION_TYPE.mobile ? (
            <MultiCarousel items={labResultImagesList} itemSpaceConfigs={carouselConfig} className="custom-dots" customDot={<CustomDot />} />
          ) : (
            <div className="hidden gap-4 lg:flex">
              {labResultImages[0] && (
                <div className="flex-1">
                  <ImageWrapper
                    key={labResultImages[0]?.id}
                    image={labResultImages[0]}
                    origin="test-page"
                    imgProps={{ className: 'rounded-3xl object-cover h-full w-full' }}
                  />
                </div>
              )}
              {labResultImages[1] && (
                <div className="flex flex-1 flex-col gap-4">
                  <ImageWrapper
                    key={labResultImages[1]?.id}
                    image={labResultImages[1]}
                    origin="test-page"
                    imgProps={{ className: cx('rounded-3xl object-cover h-full', labResultImages.length > 2 && 'max-h-[295px]') }}
                  />
                  {labResultImages[2] && (
                    <ImageWrapper
                      key={labResultImages[2]?.id}
                      image={labResultImages[2]}
                      origin="test-page"
                      imgProps={{ className: 'rounded-3xl object-cover max-h-[295px]' }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </section>
      )}
    </Tab.Group>
  );
};
