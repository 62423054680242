import React from 'react';

import { Button } from 'components/common-n4/button';

import { type ContentProps } from './types';

export const Final: React.FC<ContentProps> = () => {
  return (
    <div>
      You have completed the survey. For advanced filtering, see our comparison tool
      <Button.LinkPrimary className="mt-4" href="/hearing-aids/compare" shouldOpenNewTab={false}>
        Compare Hearing Aids
      </Button.LinkPrimary>
    </div>
  );
};
