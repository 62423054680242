import { Logger } from 'api/types';
import logger from 'lib/logger';

import { globalFilters, questions } from './questions';
import { type Product, Condition, FilterTypes, PriceRange } from './types';

export const makeArray = (maybeArray: string | string[]) => (maybeArray instanceof Array ? maybeArray : [maybeArray]).filter((a) => !!a);

export const isProductPriceWithinPriceRange = ({ product, rangeMin, rangeMax }: { product: Product; rangeMin: number; rangeMax: number }) => {
  if (product.price) {
    const p = Number(product.price.purchase_price);
    return p >= rangeMin && p <= rangeMax;
  }

  return false;
};

interface FindMatchProps {
  answers: Record<string, string | string[]>;
  priceRange?: PriceRange;
  products: Product[];
  log: Logger;
  count?: number;
}

const intersects = (maybeArray1: string | string[], maybeArray2: string | string[]) => {
  const array1 = makeArray(maybeArray1);
  const array2 = makeArray(maybeArray2);
  return array1.some((item) => array2.includes(item));
};

// all matches - not de-duped, not checked against price - used for building the histogram
// top matches - check against price first, then de-dedup - used for displaying the top 3

export const findMatches = ({ answers, priceRange, products, log, count }: FindMatchProps): Product[] => {
  if (!products?.length) {
    return [];
  }

  const globalFilter = globalFilters.find((gf) => Object.entries(gf.answers).every(([questionId, answer]) => answers[questionId] === answer));

  const matches = [];
  for (const product of products) {
    let mismatch = false;
    if (globalFilter) {
      if (!Object.entries(globalFilter.filters).every(([field, value]) => intersects(product.filters[field as FilterTypes], value))) {
        continue;
      }
    }
    if (priceRange) {
      if (!product.price || product.price.purchase_price < priceRange.min || product.price.purchase_price > priceRange.max) {
        continue;
      }
    }
    for (const questionId of Object.keys(answers)) {
      const question = questions.find((q) => q.id === questionId)!;
      const currentAnswers = makeArray(answers[questionId]);
      for (const answer of currentAnswers) {
        const option = question.options.find((o) => o.value === answer);
        if (option) {
          const { filters } = option;
          for (const filter of filters) {
            for (const filterType of Object.keys(filter)) {
              const filterValues = makeArray(filter[filterType as FilterTypes]!);
              const productValues = makeArray(product.filters[filterType as FilterTypes]);
              if (!filterValues.some((fv) => productValues.includes(fv))) {
                mismatch = true;
                break;
              }
            }
            if (mismatch) {
              break;
            }
          }
        }
      }
      if (mismatch) {
        break;
      }
    }
    if (!mismatch) {
      matches.push(product);
    }
  }
  if (!count) {
    return matches;
  }

  const sortFn = (a: Product, b: Product) => {
    if (Object.keys(answers).length === 0) {
      const ax = a.filters.tags.includes('wizard-featured') ? 0 : 1;
      const bx = b.filters.tags.includes('wizard-featured') ? 0 : 1;
      const comp = Math.sign(ax - bx);
      if (comp !== 0) {
        return comp;
      }
    }
    if ((a.score || -1) > (b.score || -1)) {
      return -1;
    }
    if ((a.score || -1) < (b.score || -1)) {
      return 1;
    }

    if (a.sortOrder < b.sortOrder) {
      return -1;
    }
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }

    if (a.level < b.level) {
      return -1;
    }
    if (a.level > b.level) {
      return 1;
    }
    return a.fullName.localeCompare(b.fullName);
  };
  const sorted = matches.sort(sortFn);

  const deduped: Product[] = [];
  const skips = [
    (p: Product, current: Product[]) => current.some((m) => m.slug === p.slug),
    (p: Product, current: Product[]) => current.some((m) => m.releaseSlug === p.slug),
    (p: Product, current: Product[]) => current.some((m) => m.base === p.base),
    (p: Product, current: Product[]) => current.some((m) => m.brand === p.brand),
    (p: Product) => !p.score,
  ];

  for (let i = 0; i < skips.length; ++i) {
    for (const product of sorted) {
      let skip = false;
      for (let j = 0; j < skips.length - i; ++j) {
        if (skips[j](product, deduped)) {
          skip = true;
          break;
        }
      }
      if (skip) {
        continue;
      }
      deduped.push(product);
      if (deduped.length === count) {
        break;
      }
    }
    if (deduped.length === count) {
      break;
    }
  }
  log.debug('deduped matches: %o', deduped);
  const resorted = deduped.sort(sortFn);
  return resorted.slice(0, count);
};

interface MatchesConditionProps {
  answers: Record<string, string | string[]>;
  conditions?: Condition[];
}

export const matchesConditions = ({ answers, conditions }: MatchesConditionProps) => {
  if (!conditions?.length) {
    return true;
  }
  return conditions.every((condition) => {
    const { questionId, answers: conditionAnswers } = condition;
    const chosenAnswers = makeArray(answers[questionId]);
    if (chosenAnswers.length === 0) {
      return false;
    }
    return chosenAnswers.every((chosenAnswer) => conditionAnswers.includes(chosenAnswer));
  });
};

export const checkAll = async (products: Product[], count: 3) => {
  const sets: Record<string, string | string[]>[] = [];
  for (const question of questions) {
    if (sets.length === 0) {
      sets.push({});
      for (const option of question.options) {
        sets.push({ [question.id]: option.value });
      }
    } else {
      const currentSets = [...sets];
      for (const set of currentSets) {
        if (matchesConditions({ answers: set, conditions: question.conditions })) {
          for (const option of question.options) {
            sets.push({ ...set, [question.id]: option.value });
            /*
            if (question.type === Widget.MultipleChoice) {
              // dumb way to get all permutations, knowing three is the max we have
              for (const iOption of question.options) {
                if (iOption.value !== option.value) {
                  sets.push({ ...set, [question.id]: [option.value, iOption.value] });
                  for (const iiOption of question.options) {
                    if (iiOption.value !== option.value && iiOption.value !== iOption.value) {
                      sets.push({ ...set, [question.id]: [option.value, iOption.value, iiOption.value] });
                    }
                  }
                }
              }
            }
            */
          }
        }
      }
    }
  }

  const log = logger({ category: 'checkAllWizardAnswerSets' });
  const setsWithMatches = sets.map((set, idx) => {
    const matches = findMatches({ answers: set, products, log, count });
    return { answers: set, matches, idx };
  });
  // console.log('swm: %o', setsWithMatches.slice(0, 1000));
  return setsWithMatches;
};

export const findMinMaxDiff = (matches: Product[]) => {
  const mins: number[] = [];
  const maxes: number[] = [];

  matches.forEach((product) => {
    if (product.price) {
      mins.push(Number(product.price.purchase_price));
      maxes.push(Number(product.price.purchase_price));
    }
  });

  let minimum = Math.min(...mins);
  let maximum = Math.max(...maxes);

  minimum = minimum === Infinity ? 0 : minimum;
  maximum = maximum === -Infinity ? 0 : maximum;
  const difference = maximum - minimum;

  return { min: minimum, max: maximum, diff: difference };
};
