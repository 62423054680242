import { type SbBlokData } from '@storyblok/react';
// import Head from 'next/head';

// import { useApiData } from 'hooks';
// import { normalizeUrl } from 'lib/utils';

interface Image {
  filename: string;
}

interface Blok extends SbBlokData {
  product_name: string;
  brand: string;
  image: Image;
  description: string;
  rating_value: string;
}

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
}

interface ProductReviewProps {
  blok: Blok;
  story: Story;
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const ProductReview: React.FC<ProductReviewProps> = ({ blok, story }) => {
  return null;
  /*
  const { authors } = useApiData();

  const article = story.content.blocks.find((b) => b.component === 'n4-article');
  const author = (article?.primary_author ? authors[article.primary_author as number] : null) || { name: 'Staff Writer' };

  const schema = {
    '@context': 'http://schema.org/',
    '@type': 'Review',
    itemReviewed: {
      '@type': 'Product',
      name: blok.product_name,
      brand: blok.brand,
      image: blok.image?.filename ? normalizeUrl({ url: blok.image.filename, origin: 'n4-product-review' }) : undefined,
      description: blok.description,
      review: {
        '@type': 'Review',
        author: {
          '@type': 'Person',
          name: author.name,
        },
      },
    },
    author: {
      '@type': 'Person',
      name: author.name,
    },
    reviewRating: {
      '@type': 'Rating',
      ratingValue: blok.rating_value,
      bestRating: '5.0',
    },
    publisher: {
      '@type': 'Organization',
      name: 'HearingTracker',
    },
  };
  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
    </Head>
  );
  */
};

export default ProductReview;
