import logger from './logger';
import { track } from './tracking';

const log = logger({ category: 'ab-test' });

/*
  segmentOrigin:
    button
    mobileButton
    link
  
  Partner or Affiliate

  position 
    article-header
    compare-product-card
    listicle-product
    mobile-footer
    product-card
    product-info-popup
    product-page-bottom
    product-page-top
    product-scorecard
    product-top-link
*/

export type AbButtonPosition =
  | 'listicle-product-list-top'
  | 'comparison-engine-top'
  | 'listicle-product-within-article'
  | 'product-page-mobile-sticky-footer'
  | 'product-card-within-article'
  | 'product-info-popup'
  | 'product-page-model-comparison'
  | 'product-page-main-scorecard'
  | 'wizard-sidebar';

export type AbLinkPosition =
  | 'product-page-top-link'
  | 'product-page-mobile-sticky-footer-link'
  | 'product-card-top-link'
  | 'listicle-product-list-top-link'
  | 'wizard-sidebar-link';

const tests: Record<string, string[]> = {
  // buttons
  'listicle-product-list-top': ['View Details'],
  'comparison-engine-top': ['View Deal'],
  'listicle-product-within-article': ['Find local savings on {release}', 'Purchase {release}', 'Save on {release}', 'View Deal'],
  'product-page-mobile-sticky-footer': ['Details'],
  'product-card-within-article': ['View Deal'],
  'product-info-popup': ['View Details'],
  'product-page-model-comparison': ['View Details'],
  'product-page-main-scorecard': ['View Deal'],
  'wizard-sidebar': ['View Deal'],

  // links
  'product-page-top-link': ['From {price} a pair {local}'],
  'product-page-mobile-sticky-footer-link': ['From {price} a pair {local}'],
  'product-card-top-link': ['From {price} a pair {local}'],
  'listicle-product-list-top-link': ['From {price} a pair {local}'],
  'wizard-sidebar-link': ['View Deal'],

  // actions - currently not using this
  providerFlow: ['dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy'],

  wizard: ['default'],

  /*
  buttonPartner: [
    'Find local savings on {release}',
    'Save on {release}',
    'Buy {release}',
    'Shop {release}',
    'View Details',
    'Request Appointment',
    'Request Contact',
    'Request Local Appointment',
    'Setup Local Visit',
    'Connect with Provider',
    'Connect with Local Provider',
  ],
  buttonAffiliate: ['Purchase {release}', 'Save on {release}', 'Buy {release}', 'Shop {release}', 'View Details', 'Get {release}'],
  mobileButtonPartner: ['Buy Now', 'Get Started', 'View Details', 'Find Savings', 'Request Contact', 'Request Appointment', 'Setup Visit'],
  mobileButtonAffiliate: ['Buy Now', 'Get Started', 'View Details', 'Find Savings', 'Check It Out', 'More Info', 'Show Me More', 'Tell Me More', 'Details'],
  link: ['with local service'],
  providerFlow: ['dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'dummy', 'popup'],
  */
};

export interface RecordAbProps {
  testName: string;
  event: string;
  segment: number;
  origin: string;
  properties?: Record<string, string>;
}

export const recordAbEvent = ({ event, segment, testName, origin, properties = {} }: RecordAbProps) => {
  log.debug('ab: %s/%d/%s', event, segment, testName);
  if (tests[testName] && segment >= 0 && segment < tests[testName].length) {
    track({
      event,
      eventType: 'abtest',
      origin,
      properties: { ...properties, cta: tests[testName][segment], testName },
    });
  }
};

export const getAb = (testName: string, substitutions: Record<string, string> = {}) => {
  let segment = -99;
  if (typeof window === 'undefined' || typeof window.localStorage === 'undefined' || !tests[testName]) {
    // eslint-disable-next-line no-console
    log.debug('No ab test for %s', testName);
    return { segment, cta: '', text: '' };
  }
  const ctas = tests[testName];
  const itemName = `ht-ab-${testName}`;
  if (localStorage.getItem(itemName)) {
    segment = parseInt(localStorage.getItem(itemName)!, 10);
  }
  if (typeof segment === 'undefined' || segment === null || segment < 0 || segment > ctas.length - 1) {
    segment = Math.floor(Math.random() * ctas.length);
    window.localStorage.setItem(itemName, segment.toString());
  }
  let text = ctas[segment];
  Object.entries(substitutions).forEach(([from, to]) => (text = text.replace(`{${from}}`, to)));
  // eslint-disable-next-line no-console
  log.debug('ab test: %s -> %o', testName, { segment, text, cta: ctas[segment] });
  return { segment, text, cta: ctas[segment] };
};
