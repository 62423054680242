import React from 'react';

import { usePathname } from 'next/navigation';

// import { Button } from 'components/common-n4/button';
import { Link } from 'components/common-n4/link';
import { useOncePerPage } from 'hooks';
import { getAb, recordAbEvent, type AbLinkPosition } from 'lib/ab-tests';
import Logger from 'lib/logger';
import { formatCurrency, normalizeUrl, tw } from 'lib/utils';
import type { Model, HearingAid, Price, ReleaseLite } from 'types/release';

import Currency from './currency';
// import { ProviderFlow } from './provider-pop-up';

const log = Logger({ category: 'price-link' });

interface PriceLinkProps {
  release: ReleaseLite;
  model?: Model;
  hearingAid?: HearingAid;
  price?: Price;
  className?: string;
  onModalOpenChange?: (isOpening: boolean) => void;
  origin: string;
  position: AbLinkPosition;
  linkPrefix?: string;
}

const elementType = 'link';
const event = 'AffiliateAction';
const utmize = (url: string, content: string, term: string) => {
  const newUrl = new URL(url);
  newUrl.searchParams.set('utm_campaign', 'lead-gen');
  newUrl.searchParams.set('utm_medium', 'button');
  newUrl.searchParams.set('utm_content', content);
  newUrl.searchParams.set('utm_term', term);
  return newUrl.toString();
};

const generateTestId = ({ release, model, hearingAid }: { release?: ReleaseLite; model?: Model; hearingAid?: HearingAid }): string => {
  if (hearingAid) {
    return `ha-${hearingAid.slug || hearingAid.id}`;
  }
  if (model) {
    return `mo-${model.slug || model.id}`;
  }
  if (release) {
    return `r-${release.slug}`;
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PriceLink: React.FC<PriceLinkProps> = ({ release, model, hearingAid, price, className, onModalOpenChange, origin, position, linkPrefix }) => {
  const [segment, setSegment] = React.useState(-2);
  // const [isProviderShowing, showProvider] = React.useState<boolean>(false);
  const useOnce = useOncePerPage();
  const pathname = usePathname();
  const [linkState, setLinkState] = React.useState({ text: 'Loading...', link: '', shouldOpenTab: false, seller: '', cta: '' });
  const [clicked, setClicked] = React.useState(0);
  const random = React.useRef(String(Math.floor(Math.random() * 10 ** 8)));

  log.debug('release: %s, price: %o', release?.slug, price);

  const testName = position;
  const partnerType = price?.partner_priority ? 'lead-gen' : 'affiliate';
  const testId = generateTestId({ release, model, hearingAid });

  if (useOnce({ item: `${testName}-${testId}-impression`, skip: segment < 0 })) {
    recordAbEvent({
      event,
      segment,
      testName,
      origin,
      properties: {
        action: 'impression',
        partnerType,
        elementType,
        release: release.slug,
        seller: price?.seller || '',
        position,
        link: price?.url ? utmize(price.url, testName, random.current) : '',
        random: random.current,
        testId,
      },
    });
  }

  if (useOnce({ item: `${testName}-${testId}-click`, skip: segment < 0 || clicked !== 1 })) {
    recordAbEvent({
      event,
      segment,
      testName,
      origin,
      properties: {
        action: 'click',
        partnerType,
        elementType,
        release: release.slug,
        seller: price?.seller || '',
        position,
        link: price?.url ? utmize(price.url, testName, random.current) : '',
        random: random.current,
        testId,
      },
    });
  }

  React.useEffect(() => {
    const onReleasePage = pathname === release.path;
    if (price) {
      // let text = `From ${formatCurrency(price.purchase_price)} a pair`;
      const {
        cta,
        text,
        segment: seg,
      } = getAb(testName, {
        release: release.name,
        price: formatCurrency(price.purchase_price),
        local: price.url?.includes('ziphearing') ? 'with local service' : '',
      });
      setSegment(seg);
      if (price.url) {
        setLinkState({ text, link: utmize(price.url, testName, random.current), shouldOpenTab: true, cta, seller: price.seller || '' });
      } else {
        if (onReleasePage) {
          setLinkState({ text, link: '', shouldOpenTab: false, cta, seller: price.seller || '' });
        } else {
          // Not using this anywhere else, but may some day
          setLinkState({
            text,
            link: normalizeUrl({ url: release.path, origin: 'price-button' }),
            shouldOpenTab: false,
            cta: 'From {price} a pair',
            seller: price.seller,
          });
        }
      }
    } else {
      if (onReleasePage) {
        setLinkState({ text: 'Price not available', link: '', shouldOpenTab: false, cta: '', seller: '' });
      } else {
        // Not using this anywhere else, but may some day
        setLinkState({ text: 'Product Info', link: release.path, shouldOpenTab: false, cta: 'Product Info', seller: '' });
      }
    }
  }, [price, pathname, release.path, testName, release.name, origin, random]);

  /*
  const handleSearch = () => {
    setClicked((count) => count + 1);
    showProvider(true);
    if (onModalOpenChange) {
      onModalOpenChange(true);
    }
  };

  const onClose = () => {
    showProvider(false);
    if (onModalOpenChange) {
      onModalOpenChange(false);
    }
  };
  
  if (price?.provider_search) {
    // display popup
    return (
      <>
        <ProviderFlow
          release={release}
          model={model}
          hearingAid={hearingAid}
          onClose={onClose}
          isModalOpen={isProviderShowing}
          origin={origin}
          segment={segment}
          segmentOrigin={testName}
          position={position}
        />
        <Button.LinkButton className={tw('flex-wrap !justify-normal !gap-[0.2rem] !p-0', className)} onClick={handleSearch}>
          <span key="prefix">From </span>
          <Currency key="currency" price={price.purchase_price} fallback={'N/A'} />
          <span key="suffix">a pair with local service</span>
        </Button.LinkButton>
      </>
    );
  }
  */

  if (linkState.link) {
    return (
      <Link
        variant="primary"
        className={tw('!justify-normal !gap-[0.2rem] !p-0', className)}
        href={linkState.link}
        onClick={() => {
          setClicked((count) => count + 1);
          return true;
        }}
        shouldOpenNewTab={linkState.shouldOpenTab}
        data-track={!!linkState.cta}
        data-event-name="Link"
        data-event-position="product-top-link"
        data-event-product={release.slug}
        data-event-cta="from {price} a pair with local service"
        data-event-seller={linkState.seller}
        data-event-url={linkState.link}
      >
        {linkPrefix ? (
          <>
            {linkPrefix}
            <Currency key="currency" price={price?.purchase_price} fallback={'N/A'} />
          </>
        ) : (
          linkState.text
        )}
      </Link>
    );
  }

  return <span className={tw('!justify-normal !gap-[0.2rem] !p-0', className)}>{linkState.text}</span>;
};

export default PriceLink;
